import React, { useCallback, Suspense, useState, useEffect } from 'react';
import {
	CAccordion,
	CAccordionItem,
	CAccordionHeader,
	CAccordionBody,
} from '@coreui/react';
import Navbar from 'components/Navbar';
import Header from 'components/Header';
import Footer from 'components/Footer';
import AboutCC from 'assets/images/footer-ce.svg';
import SubscribeButton from 'components/SubscribeButton';
import DOMPurify from 'dompurify';

function About() {
	const [accordionData, setAccordionData] = useState([]);
	const [expandedItems, setExpandedItems] = useState({});
	const [setting, setSetting] = useState({});
	const handleToggle = (id) => {
		setExpandedItems((prevState) => ({
			...prevState,
			[id]: !prevState[id],
		}));
	};

	const fetchYoutubeUrl = () => {
		const storedSettings = localStorage.getItem('settings');
		if (storedSettings) {
			const settings = JSON.parse(storedSettings);
			const url = settings['about_page'].youtubeUrl;

			const videoIdMatch = url.match(/(?:youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^&\n]{11})/);
			if (videoIdMatch) {
				return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
			}
		}
		return '';
	};

	const youtubeUrl = fetchYoutubeUrl();

	const fetchAccordionData = useCallback(async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}guest/setting-about/accordion/get`);
			const data = await response.json();
			setAccordionData(data.data || []);
		} catch (error) {
			console.error('Featured accordion not found:', error);
		}
	}, []);

	useEffect(() => {
		const storedSettings = localStorage.getItem('settings');
		if (storedSettings) {
			const settings = JSON.parse(storedSettings);
			setSetting(settings['about_page']);
		}
		fetchAccordionData();
	}, [fetchAccordionData]);

	const RenderHtml = ({ htmlContent }) => {
		const sanitizedHTML = DOMPurify.sanitize(htmlContent);
	  
		return (
		  <span dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
		);
	};

	return (
		<>
			<Navbar />
			<Header page='about_page' />
			<div className='container-fluid bg-black text-white pb-3'>
				<div className='container container-about-start'>
					<div className='py-4'>
						<h1>{setting.sectionTwoHeading}</h1>
						<h5>{setting.sectionTwoSubHeading}</h5>
						<p>{setting.sectionTwoDescription}</p>
					</div>
				</div>
			</div>
			<div className='container-fluid bg-green text-white container-about-mid'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-5 p-2'>
							<Suspense fallback={<div>Loading video...</div>}>
								{youtubeUrl ? (
									<iframe
										className="w-100"
										width="560"
										height="315"
										src={youtubeUrl}
										title="YouTube video player"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									></iframe>
								) : (
									<div>No video available</div>
								)}
							</Suspense>
						</div>
						<div className='col-md-7 px-4'>
							<h1>{setting.sectionThreeHeading}</h1>
							<p>
								<RenderHtml htmlContent={setting.sectionThreeDescription} />
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='bg-black text-white about-accordion'>
				<div className="container">
					<h1>MSL at a Glance</h1>
					<div className="row">
						{Array.isArray(accordionData) && accordionData.map(({ _id, title, content, description }) => (
							<div className="col-md-6 mt-2" key={_id}>
								<CAccordion className="text-white" activeItemKey={1}>
									<CAccordionItem itemKey={_id}>
										<CAccordionHeader className="text-white">{title}</CAccordionHeader>
										<CAccordionBody>
											<p>{expandedItems[_id] ? description : content}</p>
											{/* <button
												className="btn btn-pink border-1 border-white text-white mt-2"
												onClick={() => handleToggle(_id)}
											>
												{expandedItems[_id] ? 'Show Less' : 'Show More'}
											</button> */}
										</CAccordionBody>
									</CAccordionItem>
								</CAccordion>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className='about-end'>
				<div className='container text-white'>
					<h1>Why Choose LED Lighting?</h1>
					<div className='row justify-content-between'>
						<div className='col-md'>
							<ul className="custom-list">
								<li>
									<p>LEDs are more than 8 times more efficient than traditional bulbs. The use of LEDs can save more than 90% of the electrical utility cost over incandescent bulbs. (Great for both the environment and your wallet)</p>
								</li>
								<li>
									<p>The savings don’t stop there. LEDs have a longer lifespan than incandescent bulbs, creating additional savings on replacement costs and maintenance hours.</p>
								</li>
								<li>
									<p>Gain a marketing advantage! LED applications are most effective for amusement park signs and rides. The bright, vivid colors mounted on Ferris Wheels and other rides become giant billboards for advertising from great distances. Traditional rides receive a new “SPARK” and become the sudden center of interest.</p>
								</li>
							</ul>
						</div>
						<div className='offset-md-1 col-md'>
							<ul className="custom-list">
								<li>
									<p>Our LED lighting provides the most versatile presentation available. A simple press of a button changes the entire theme. Color scheme, flash, and direction can be established in the blink of an eye from a variety of available programs. This added value encourages extended stays and additional rides. The show is impressive enough to encourage repeat visits, and “word of mouth” advertising brings new patrons to the park.</p>
								</li>
								<li>
									<p>Maxtron products are CE certified and UL approved. (CE [TÜV Süd], UL and CSA [ETL Intertek])</p>
								</li>
								<li>
									<p>All components are branded goods, i.e., Osram, Atmel, Covestro, Dow Corning, and more.</p>
								</li>
								<li>
									<p>Except for the components, we produce completely in-house and thus have full control over our products.</p>
								</li>
								<li>
									<p>All Maxtron products are under strict quality control.</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='bg-black text-white about-cc'>
				<div className="container d-flex justify-content-between">
					<div>
						<p>
							All MSL light modules are CE, UL, and CSA certified. All modules are P67 tested.
							It is important for us, as well as for our customers, to know that Maxtron
							products are a safe way of using LED lighting. While many LED producers are just manufacturing some LED lighting without any kind of safety standards or choosing the right materials, 
							approval from these institutions and agencies gives us and the user peace of mind.
						</p>
					</div>
					<div>
						<img src={AboutCC} className='p-2' alt='Building' />
					</div>
				</div>
			</div>
			<Footer />
			<SubscribeButton />
		</>
	);
}

export default About;
